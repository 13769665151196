@import './scss/fonts';
@import './scss/scss-variables';
@import './scss/media-queries';

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  scroll-behavior: smooth;
   background: var(--main-bg);
}
ol,
ul {
  list-style: none;
}

/* Fixes accessibility issue in Safari. https://developer.mozilla.org/en-US/docs/Web/CSS/list-style */
li::before {
  content: '\200B';
  // Preventing empty ::before from having height
  display: block;
  height: 0;
  width: 0;
}
blockquote,
q {
  quotes: none;
}
blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Unbounded-VariableFont', sans-serif;
}

h1 {
  font-size: var(--font-size-48);
  line-height: var(--size-64);

  @include respond(tab-land) {
    font-size: var(--font-size-72);
    line-height: var(--size-80);
  }
}

h2 {
  font-size: var(--font-size-36);
  line-height: var(--size-40);

  @include respond(tab-land) {
    font-size: var(--font-size-60);
    line-height: var(--size-64);
  }
}

h5 {
  font-family: 'WixMadeforDisplay-Regular', sans-serif;
  font-size: var(--font-size-18);
  line-height: var(--size-24);

  @include respond(tab-land) {
    font-size: var(--size-22);
    line-height: var(--size-32);
  }

  @include respond(extra-big-desktop) {
    font-size: var(--font-size-32);
    line-height: var(--size-48);
  }
}

p {
  font-size: var(--font-size-14);
  line-height: var(--size-24);

  @include respond(tab-land) {
    font-size: var(--font-size-18);
    line-height: var(--size-28);
  }

  @include respond(extra-big-desktop) {
    font-size: var(--font-size-32);
    line-height: var(--font-size-36);
  }
}

p,
a {
  font-family: 'Unbounded-VariableFont', sans-serif;
}
