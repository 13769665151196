@import '../../scss/media-queries';

.content-section {
  z-index: 1;
  min-height: var(--size-640);
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: var(--content-width);
  margin: 0 auto;
  padding: 0 var(--size-24);
  box-sizing: border-box;
  width: 100%;

  @include respond(phone-landscape) {
    min-height: var(--size-512);
  }

  @include respond(tab-land) {
    min-height: var(--size-812);
  }

  &__children {
    max-width: var(--content-width);
    margin: 0 auto;
  }
}
