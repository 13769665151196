@import './scss-variables';

@mixin respond($breakpoint) {
  /* Double extra small phone devices */
  @if $breakpoint == double-extra-small-phone {
    @media only screen and (max-width: $breakpoint-double-extra-small-phone) {
      @content;
    }
  }

  /* Extra small phone devices */
  @if $breakpoint == extra-small-phone {
    @media only screen and (max-width: $breakpoint-extra-small-phone) {
      @content;
    }
  }

  /* Small phone devices */
  @if $breakpoint == small-phone {
    @media only screen and (max-width: $breakpoint-small-phone) {
      @content;
    }
  }

  /* Extra small devices (phones and down) */
  @if $breakpoint == phone {
    @media only screen and (max-width: calc(#{$breakpoint-phone} - 0.0625em)) {
      @content;
    }
  }

  @if $breakpoint == phone-landscape {
    @media only screen and (orientation: landscape) and (min-width: 20.0625rem) and (max-width: 62rem) {
      @content;
    }
  }

  /* Small devices (portrait tablets and large phones and up) */
  @if $breakpoint == tab-port {
    @media only screen and (min-width: $breakpoint-phone) {
      @content;
    }
  }

  /* Medium devices (landscape tablets and up) */
  @if $breakpoint == tab-land {
    @media only screen and (min-width: $breakpoint-tab-land) {
      @content;
    }
  }

  /* Large devices (laptops/desktops and up) */
  @if $breakpoint == desktop {
    @media only screen and (min-width: $breakpoint-desktop) {
      @content;
    }
  }

  /* Extra large devices (large laptops and desktops and up) */
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: $breakpoint-big-desktop) {
      @content;
    }
  }

  @if $breakpoint == extra-big-desktop {
    @media only screen and (min-width: $breakpoint-extra-big-desktop) {
      @content;
    }
  }
}
