@import '../../../../scss/media-queries';

.who_container {
  position: relative;

  h2 {
    margin-bottom: var(--size-18);
  }

  h5 {
    margin-bottom: var(--size-72);
  }

  &__content {
    position: relative;
    z-index: 1;
    color: var(--white);
    text-align: center;
    max-width: var(--content-width);
    margin: 0 auto;
    padding: var(--size-64) var(--size-24);
    box-sizing: border-box;
  }

  &__services {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__background {
    position: absolute;
    width: 100%;
    z-index: 0;
    left: 0;
    top: -14vw;
  }
}
