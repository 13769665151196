@import '../../scss/media-queries';

.header {
  width: 100%;
  position: relative;

  @include respond(tab-land) {
    min-height: var(--size-812);
    padding-bottom: var(--size-64);
  }

  @include respond(extra-big-desktop) {
    padding-bottom: var(--size-200);
  }

  &__nav {
    z-index: 2;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: var(--size-24);
    max-width: var(--content-width);
    margin: 0 auto;
    box-sizing: border-box;

    &-img {
      height: 0;
      transition: height 0.5s ease-out;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;

      &--open {
        height: 80vh;
        transition: height 0.5s ease-in;

        @include respond(phone-landscape) {
          height: 90vw;
        }

        @include respond(tab-land) {
          height: 30vh;
        }
      }
    }

    .navigation {
      pointer-events: none;
      transition: transform 0.5s ease-out;
      transform: translateY(-15.75rem);
      margin-left: calc(-1 * var(--size-32));

      @include respond(tab-land) {
        transform: translateY(calc(-1 * var(--size-56)));
        margin-left: var(--size-32);
      }

      @include respond(extra-big-desktop) {
        transform: translateY(-4.25rem);
      }
    }

    svg {
      line {
        stroke: var(--brand-yellow);
        transition: stroke 0.5s ease-in;
      }

      rect,
      path {
        fill: var(--brand-yellow);
        transition: fill 0.5s ease-in;
      }
    }

    &--open {
      z-index: 3;
      position: relative;

      svg {
        line {
          stroke: var(--brand-main);
        }

        rect,
        path {
          fill: var(--brand-main);
        }
      }

      .navigation {
        pointer-events: all;
        transform: translateY(0);
        transition: transform 0.5s ease-in;
      }

      .header {
        &__right {
          opacity: 0;
          transition: opacity 0.25s ease-out;

          @include respond(tab-land) {
            opacity: 1;
          }
        }

        &__logo-wrapper {
          top: 50vh;
          z-index: 6;
          transition: top 0.5s ease-in;
        }

        &__title {
          opacity: 0;

          @include respond(tab-land) {
            opacity: 1;
          }
        }
      }
    }
  }

  &__left,
  &__right {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    z-index: 5;
  }

  &__left {
    flex: 1;
    position: relative;
    justify-content: center;

    @include respond(tab-port) {
      justify-content: flex-start;
    }

    .icon-button {
      z-index: 9;
    }
  }

  &__logo-wrapper {
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 2;
    transition: top 0.5s ease-in;

    img {
      max-width: var(--size-282);

      @include respond(tab-land) {
        margin-bottom: var(--size-57);
      }
    }
  }

  &__logo {
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transform: translateY(calc(-1 * var(--size-180)));
    transition: transform 0.5s ease-out;

    @include respond(tab-land) {
      transform: none;
    }

    &--open {
      transform: translateY(0);
      transition: transform 0.5s ease-in;
    }
  }

  &__title {
    width: 100%;
    opacity: 1;
    text-align: center;
    color: var(--white);
    transition: opacity 0.5s ease-in;
    transform: translateY(calc(-1 * var(--size-112)));

    @include respond(tab-land) {
      transform: none;
    }

    h1 {
      margin-bottom: var(--size-8);
    }
  }

  &__background {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: -1;
    object-fit: cover;
  }
}
