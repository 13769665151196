@import '../../../scss/media-queries';

.icon-text {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 var(--size-8);
  flex: 1;

  &__img {
    max-width: var(--size-90);
    margin-bottom: var(--size-24);
  }

  p {
    text-transform: uppercase;

    &:nth-child(2) {
      color: var(--brand-yellow);
      margin-bottom: var(--size-32);
    }

    @include respond(extra-big-desktop) {
      font-size: var(--size-24);
      line-height: var(--size-38);
    }
  }
}
