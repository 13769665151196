@font-face {
    font-family: 'Unbounded-VariableFont';
    src: url(../fonts/Unbounded-VariableFont_wght.ttf);
}

@font-face {
    font-family: 'WixMadeforDisplay-Bold';
    src: url(../fonts/WixMadeforDisplay-Bold.ttf);
}

@font-face {
    font-family: 'WixMadeforDisplay-Regular';
    src: url(../fonts/WixMadeforDisplay-Regular.ttf);
}

@font-face {
    font-family: 'WixMadeforDisplay-SemiBold';
    src: url(../fonts/WixMadeforDisplay-SemiBold.ttf);
}
