@import '../../scss/media-queries';

.services {
  position: relative;

  .header {
    min-height: auto;
    padding-bottom: var(--size-128);
  }

  &__content {
    p {
      position: relative;
      z-index: 3;
      text-align: center;
    }

    &.content-section-with-images {
      background-color: var(--brand-yellow);

      @include respond(desktop) {
        .bottom {
          top: 10%;
        }
      }
    }
  }

  &__contact-link {
    z-index: 5;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -15vw;

    @include respond(tab-land) {
      bottom: -8vw;
    }

    @include respond(extra-big-desktop) {
      bottom: -6vw;
    }
  }

  &__gallery {
    padding: var(--size-256) 0 var(--size-128);
  }
}

.swiper {
  width: 100%;
  height: 100%;
  padding-top: var(--size-128);
  margin-bottom: var(--size-32);
}

.swiper-slide {
  text-align: center;
  background: var(--main-bg);

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.swiper-pagination-bullet {
  background-color: var(--white);
}
