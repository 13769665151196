@import '../../scss/media-queries';

.home {
  position: relative;

  h2 {
    margin-bottom: var(--size-18);
  }

  h5 {
    margin-bottom: var(--size-72);
  }

  &__content {
    position: relative;
    z-index: 1;
    color: var(--white);
    text-align: center;
  }

  &__services {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__play {
    z-index: -1;
    text-align: center;
    position: relative;
    width: 100%;
  }

  &__play-background {
    z-index: -1;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    object-fit: cover;
  }
}
