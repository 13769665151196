@import '../../../scss/media-queries';

.icon-button {
  border: 0;
  cursor: pointer;
  background-color: transparent;
  height: 1em;
  width: 1em;
  line-height: 1;

  &--disabled {
    opacity: 0.5;
  }

  &--small {
    font-size: 0.8125rem;
    width: 0.8125rem;

    svg {
      width: 0.8125rem;
    }
  }

  &--medium {
    font-size: var(--size-20);
    width: var(--size-20);

    svg {
      width: var(--size-20);
    }
  }

  &--large {
    font-size: var(--size-57);
    width: var(--size-57);

    svg {
      width: var(--size-57);
    }
  }

  &--x-large {
    font-size: var(--size-32);
    width: var(--size-32);

    svg {
      width: var(--size-32);
      height: var(--size-32);
    }

    @include respond(extra-big-desktop) {
      font-size: var(--size-57);
      width: var(--size-57);

      svg {
        width: var(--size-57);
      }
    }
  }

  &--inherit {
    font-size: inherit;
  }
}
