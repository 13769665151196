@import '../../scss/media-queries';

.about {
  position: relative;

  .header {
    min-height: auto;
    padding-bottom: var(--size-128);
  }

  .content-section__children {
    padding-bottom: var(--size-32);
    box-sizing: border-box;

    @include respond(extra-big-desktop) {
      padding-top: var(--size-128);
    }
  }

  h2 {
    z-index: 1;
    position: relative;
    text-align: center;
    color: var(--white);
    padding-bottom: var(--size-32);
  }

  p {
    z-index: 1;
    position: relative;
    text-align: center;
    color: var(--white);
  }

  &__contact-link {
    z-index: 5;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10vw;

    @include respond(tab-land) {
      bottom: -7vw;
    }
  }

  &__gallery {
    padding: var(--size-128) 0;
  }

  &__content {
    &.content-section-with-images {
      background-color: var(--brand-yellow);

      p,
      h2 {
        color: var(--black) !important;
        padding-bottom: var(--size-32);
      }
    }
  }
}
