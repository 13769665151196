@import '../../scss//media-queries';

.social {
  // temporary solution
  display: none;
  flex-direction: column;

  @include respond(tab-port) {
    flex-direction: row;
  }

  &__link {
    width: var(--size-40);
    margin-bottom: var(--size-8);

    @include respond(tab-port) {
      margin-bottom: 0;
      flex-direction: row;
    }

    @include respond(extra-big-desktop) {
      width: var(--size-50);
    }

    &:not(:last-child) {
      margin-right: var(--size-12);
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
