@import '../../scss/media-queries';

.content-section-with-images {
  position: relative;

  &__children {
    max-width: var(--content-width);
    margin: 0 auto;
    padding: 0 var(--size-24);
    box-sizing: border-box;
  }

  .background {
    position: absolute;
    width: 100%;
    z-index: 0;
  }

  .top {
    top: -12vw;
    left: 0;
    transform: rotate(180deg);
  }

  .bottom {
    top: 90%;
    right: 0;

    @include respond(tab-land) {
      top: 60%;
    }
  }
}
