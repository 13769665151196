@import '../../scss/media-queries';

.navigation {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  flex: 1;
  
  @include respond(tab-land) {
    width: 100%;
    flex-direction: row;
    padding-top: var(--size-6);
    margin-left: var(--size-32);
  }

  @include respond(extra-big-desktop) {
    padding-top: var(--size-18);
  }

  a {
    min-width: var(--size-200);
    font-weight: 600;
    text-align: center;
    padding: var(--size-18) 0;
    color: var(--brand-main);
    text-transform: uppercase;
    font-size: var(--font-size-18);
    border-bottom: 1px solid var(--white);

    @include respond(tab-land) {
      padding: 0 var(--size-32) 0 0;
      min-width: unset;
      border-bottom: none;
      width: auto;
    }

    @include respond(extra-big-desktop) {
      font-size: var(--font-size-32);
    }

    &:last-child {
      padding-right: 0;
    }
  }
}