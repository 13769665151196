@import '../../scss/media-queries';

.footer {
  z-index: 1;
  position: relative;
  min-height: var(--size-768);
  padding-bottom: var(--size-128);

  @include respond(extra-big-desktop) {
    padding-bottom: var(--size-192);
  }

  img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    object-fit: cover;
  }

  &__title-wrapper {
    text-align: center;
    padding-top: var(--size-128);

    @include respond(extra-big-desktop) {
      padding-top: var(--size-64);
    }
  }

  &__title {
    color: var(--brand-main);
    font-size: var(--size-18);
    line-height: var(--size-32);
    position: relative;

    @include respond(tab-land) {
      font-size: var(--size-32);
    }

    @include respond(extra-big-desktop) {
      font-size: var(--size-48);
      line-height: var(--size-64);
    }
  }

  &__sub-title {
    color: var(--white);
    line-height: var(--size-32);
    font-size: var(--font-size-20);
    position: relative;

    @include respond(tab-land) {
      font-size: var(--size-48);
      line-height: var(--size-64);
    }

    @include respond(extra-big-desktop) {
      font-size: var(--size-60);
      line-height: var(--size-64);
    }
  }

  &__license {
    font-weight: bold;
    text-align: center;
    color: var(--white);
    margin: var(--size-32) 0 var(--size-6);
    font-size: var(--font-size-12);
    position: relative;

    @include respond(extra-big-desktop) {
      font-size: var(--font-size-20);
      margin-top: var(--size-64);
    }
  }

  &__contact {
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: var(--white);
    line-height: var(--size-32);
    font-size: var(--font-size-20);

    p:not(:last-child) {
      padding-bottom: var(--size-12);

      @include respond(extra-big-desktop) {
        padding-bottom: var(--size-24);
        line-height: var(--size-48);
      }
    }
  }

  &__email {
    font-weight: bold;
    color: var(--brand-yellow);
    font-size: var(--size-14);
    position: relative;

    @include respond(tab-land) {
      font-size: var(--font-size-28);
    }

    @include respond(extra-big-desktop) {
      font-size: var(--font-size-36);
    }
  }
}