@import '../../scss/media-queries';

.contact {
  width: 100%;

  .header {
    min-height: auto;
    padding-bottom: var(--size-180);
  }

  &__content {
    p {
      position: relative;
      z-index: 3;
      text-align: center;
    }

    &.content-section-with-images {
      background-color: var(--brand-yellow);

      @include respond(desktop) {
        .bottom {
          top: 10%;
        }
      }
    }
  }

  &__form-wrapper {
    margin-bottom: var(--size-128);
    padding-top: var(--size-64);

    @include respond(tab-land) {
      padding-top: var(--size-128);
    }
  }

  &__title {
    text-align: center;
    color: var(--brand-orange);
    margin: var(--size-32) 0;
    line-height: var(--size-24);
    padding: 0 var(--size-24);

    @include respond(phone-landscape) {
      margin: var(--size-128) 0 var(--size-12);
    }

    @include respond(tab-land) {
      margin: var(--size-64) 0;
    }
  }

  &__form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    button {
      width: 100%;
      text-transform: uppercase;
      background-color: var(--brand-yellow);
      border-radius: var(--size-32);
      padding: var(--size-12) 0;
      font-size: var(--font-size-18);
      cursor: pointer;

      @include respond(tab-land) {
        font-size: var(--font-size-24);
        border-radius: var(--size-48);
      }

      @include respond(extra-big-desktop) {
        font-size: var(--font-size-48);
        border-radius: var(--size-82);
        padding: var(--size-32) 0;
      }
    }
  }

  &__form-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond(tab-land) {
      flex-direction: row;
    }
  }

  &__form-col {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include respond(tab-land) {
      width: 48%;
    }

    &--full {
      width: 100%;
    }
  }

  &__form-error {
    color: red;
    position: absolute;
    font-size: var(--font-size-14);
    padding-left: var(--size-16);
    bottom: var(--size-10);

    @include respond(extra-big-desktop) {
      font-size: var(--font-size-18);
    }
  }

  &__form-col-item {
    display: flex;
    flex-direction: column;
    position: relative;

    input,
    textarea {
      display: flex;
      border-radius: var(--size-32);
      padding: var(--size-12) var(--size-24);
      font-size: var(--font-size-18);
      color: var(--brand-main);
      background: var(--white);
      box-sizing: border-box;
      margin-bottom: var(--size-32);

      @include respond(extra-big-desktop) {
        border-radius: var(--size-82);
        padding: var(--size-32) var(--size-64);
        font-size: var(--font-size-32);
      }
    }
  }
}
