@import '../../../../scss/media-queries';

.play_container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-bottom: var(--size-18);
  }

  h5 {
    margin-bottom: var(--size-72);
  }

  &__link {
    z-index: 3;
    position: absolute;
    top: 3vw;
    left: 50%;
    transform: translateX(-50%);
  }

  &__content {
    position: relative;
    z-index: 1;
    color: var(--white);
    padding: var(--size-180) var(--size-24) 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    align-items: center;
    max-width: var(--content-width);
    text-align: center;
    
    @include respond(tab-land) {
      min-height: var(--size-768);
    }
  }

  &__top-wrapper {
    position: relative;
  }

  &__play-background {
    z-index: -1;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    object-fit: cover;
  }

  &__background {
    position: absolute;
    width: 100%;
    z-index: 0;
    left: 0;
    transform: rotate(180deg);
    top: -5vw;
    right: 0;
  }
}
